import { Component, OnDestroy, OnInit } from '@angular/core';
// import { Router } from '@angular/router';
import { Device } from '@capacitor/device';
// import { Share } from '@capacitor/share';
import { MenuController } from '@ionic/angular';
import { Subscription } from 'rxjs';
import { filter, tap } from 'rxjs/operators';
import { environment } from 'src/environments/environment';
import { Country } from './interfaces/country.interface';
import { AuthService } from './services/auth.service';
import { CurrentCountryService } from './services/current-country.service';
import { MenuItem, MenuService } from './services/menu.service';

@Component({
  selector: 'app-menu',
  templateUrl: './menu.component.html',
  styleUrls: ['./menu.component.scss'],
})
export class MenuComponent implements OnInit, OnDestroy {
  appVersion =  environment.version;
  menuItems: MenuItem[];
  country: Country;

  subscription: Subscription;
  mobile: boolean;

  so: any;

  constructor(
    // private router: Router,
    private menuService: MenuService,
    private authService: AuthService,
    private currentCountryService: CurrentCountryService,
    private menuController: MenuController
  ) {}

  async ngOnInit() {
    if (this.isMobile()) {
      this.mobile = true;
    } else {
      this.mobile = false;
    }
    this.subscription = new Subscription();

    this.subscription.add(
      this.authService.isLoggedIn
        .pipe(
          filter((loggedIn) => loggedIn),
          tap(() => this.logDeviceInfo()),
          tap(() => this.menuService.buildMenu().then((menu) => (this.menuItems = menu)))
        )
        .subscribe()
    );

    this.subscription.add(
      this.currentCountryService
        .getObservable()
        .pipe(tap((country) => (this.country = country)))
        .subscribe()
    );
  }

  ngOnDestroy() {
    this.subscription?.unsubscribe();
  }

  async logDeviceInfo() {
    const info = await Device.getInfo();
    console.log('logDeviceInfo:', info);
    this.so = info
  }

  downloadApp() {
    const deviceInfo = this.so

    if (deviceInfo.operatingSystem.toLowerCase() === 'mac' || deviceInfo.operatingSystem.toLowerCase() === 'ios') {
      window.location.href = "https://apps.apple.com/ar/app/tributo-simple-monotributo/id1571573160";

    } else {
      window.location.href = "https://play.google.com/store/apps/details?id=tributosimple.anfler.com.ar&hl=es_AR";

    }
  }

  async shareApp() {
    if (this.isMobile()) {
      // const url = 'http://www.tributosimple.com/argentina/compartir-descargar-app';
      // await Share.share({
      //   title: 'Compartir TributoSimple',
      //   text: '¡Hola! Te comparto TributoSimple, Tu contador en una app',
      //   url,
      // });
      this.menuController.close();
    } else {
      const url = 'http://www.tributosimple.com/argentina/compartir-descargar-app';
      window.open(url, '_system');
    }
  }

  async logout() {
    // Realiza las acciones necesarias al salir (puede incluir limpiar el estado de autenticación, etc.)
    // ...

    // Redirige a la página de login
    setTimeout(() => {
      // Redirige a la página de login
      // this.router.navigate(['/login']); // Asegúrate de proporcionar la ruta correcta para tu aplicación
    }, 1000);
  }

  private isMobile(): boolean {
    return /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent);
  }
}
