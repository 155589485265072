import { HttpClient } from '@angular/common/http';
import { Component, ComponentFactoryResolver, OnInit, Type, ViewChild } from '@angular/core';
import { NavigationExtras, Router } from '@angular/router';
import { Device } from '@capacitor/device';
import { ModalController } from '@ionic/angular';
import * as moment from 'moment';
import { PendingReviewComponent } from 'src/app/components/modals/pending-review/pending-review.component';
import { SlidesArComponent } from 'src/app/countries/ar/pages/home/slides/slides.component';
import { SlidesPeComponent } from 'src/app/countries/pe/pages/home/slides/slides.component';
import { SlidesUyComponent } from 'src/app/countries/uy/pages/home/slides/slides.component';
import { HomeSlideDirective } from 'src/app/directives/home-slide.directive';
import { ReasonENUM } from 'src/app/interfaces/common.interfaces';
import { HomeSlide } from 'src/app/interfaces/home-slide.interface';
import { IReqConsultaNew } from 'src/app/interfaces/requests/requests.interfaces';
import { ApiConsultaService } from 'src/app/services/api-consulta.service';
import { AppLogService } from 'src/app/services/appLog.service';
import { CurrentCountryService } from 'src/app/services/current-country.service';
import { LoadingService } from 'src/app/services/misc/loading.service';
import { PushNotificationService } from 'src/app/services/native/push-notification.service';
import { NavigationService } from 'src/app/services/navigation.service';
import { TabItem, TabsService } from 'src/app/services/tabs.service';
import { IUserData, UDEstateENUM } from 'src/app/services/user/user-data.interface';
import { UserDataService } from 'src/app/services/user/user-data.service';
import { ZohoService } from 'src/app/services/zoho.service';
import { ApiNotificationsService } from '../../services/api-notifications.service';

@Component({
  selector: 'app-home',
  templateUrl: './home.page.html',
  styleUrls: ['./home.page.scss'],
})
export class HomePage implements OnInit {
  @ViewChild(HomeSlideDirective) homeSlideHost: HomeSlideDirective;

  udeStates = UDEstateENUM;
  userData: IUserData;
  unreadNotifications: number;
  info: any;

  tabs: TabItem[] = [];
  modal: boolean;

  constructor(
    private http: HttpClient,
    private router: Router,
    private userDataService: UserDataService,
    private apiConsultaService: ApiConsultaService,
    private currentCountryService: CurrentCountryService,
    private navigationService: NavigationService,
    private apiNotificationsService: ApiNotificationsService,
    private appLoggerService: AppLogService,
    private loadingService: LoadingService,
    private pushNotificationService: PushNotificationService,
    private resolver: ComponentFactoryResolver,
    private tabsService: TabsService,
    private modalController: ModalController,
    private zohoService: ZohoService
  ) {
    moment.locale('es');
    this.pushNotificationService.initService();
  }

  ngOnInit() {
    this.logDeviceInfo();
    localStorage.removeItem('paymentId');

    if (localStorage.getItem('paymentId')) {
      localStorage.removeItem('paymentId');
    }

    this.zohoService.algunaFuncionAsincronica();

    this.userDataService.getUserData().subscribe(
      (userData) => {
        this.userData = userData;
        // this.verificarUbicacionUsuario()
      },
      (error) => {
        console.error('Error al obtener los datos del usuario', error);
      }
    );
  }

  async logDeviceInfo() {
    const info = await Device.getInfo();
    console.log('logDeviceInfo:', info);
    this.info = info;
    localStorage.setItem('info', JSON.stringify(this.info));
    if (this.info.platform === 'web') {
      const lastShown = localStorage.getItem('lastOpenedAppBannerTributoSimple');
      const oneWeek = 7 * 24 * 60 * 60 * 1000; // Milisegundos en una semana

      if (!lastShown || new Date().getTime() - new Date(lastShown).getTime() > oneWeek) {
        this.modal = true;
        localStorage.setItem('lastOpenedAppBannerTributoSimple', new Date().toISOString());
      } else {
        this.modal = false;
      }
    }
  }

  verificarUbicacionUsuario() {
    // Verifica si el usuario está logueado
    if (this.userData && this.userData.pais) {
      // Verifica si el código del país es "ar" en la información del usuario
      if (this.userData.pais.code.toLowerCase() === 'ar') {
        // Si el código del país es "ar", el usuario está en Argentina
        console.log('El usuario está en Argentina');
        const pais = this.userData.pais.code;
        console.log(pais, '');
      } else {
        // Si el código del país no es "ar", realiza la redirección
        console.log('El usuario no está en Argentina');
        const eventValue = { page: 'home' };
        this.appLoggerService.logPageView(eventValue);
        this.router.navigate(['/prox/home']);
      }
    } else {
      // Si el usuario no está logueado, puedes manejar esta situación de acuerdo a tus necesidades
      console.log('El usuario no está logueado');
      // Por ejemplo, podrías redirigirlo a la página de inicio de sesión
      this.router.navigate(['/login']);
    }
  }

  ionViewWillEnter() {
    this.init();
  }

  async init() {
    await this.loadingService.present();

    // Unread Notifications
    this.apiNotificationsService.getNotifications(0, 0).subscribe((res) => {
      if (res) {
        this.unreadNotifications = res?.no_leidos;
        if (this.unreadNotifications > 999) this.unreadNotifications = 999;
      }
    });

    // UserData
    this.userData = await this.userDataService.refreshUserInfo();
    console.log('userData:', this.userData);

    this.loadingService.dismiss();

    if (this.userData) {
      this.verificarEstado();

      let component: Type<HomeSlide>;

      // TODO: Verificar que funcione en deploy.
      switch (this.currentCountryService.get().id) {
        case 'ar': {
          component = SlidesArComponent;
          break;
        }
        case 'pe': {
          component = SlidesPeComponent;
          break;
        }
        case 'uy': {
          component = SlidesUyComponent;
          break;
        }
      }

      if (component) {
        this.homeSlideHost.viewContainerRef.clear();
        const contentFactory = this.resolver.resolveComponentFactory(component);
        const contentRef = this.homeSlideHost.viewContainerRef.createComponent(contentFactory);
        contentRef.instance.userData = this.userData;
        contentRef.instance.dateNow = new Date();
      }

      this.tabs = await this.tabsService.buildTabs();
    }
  }

  async verificarEstado() {
    const { estado, id } = this.userData;

    switch (estado) {
      case UDEstateENUM.ESTADO_CONTRATADO:
        if (this.userData.tiene_clave_afip) {
          const body: IReqConsultaNew = {
            motivo: ReasonENUM.CAMBIO_PLAN,
          };

          this.apiConsultaService.putQuery(body).subscribe(
            async () => {
              await this.userDataService.refreshUserInfo(); // Actualizar datos del usuario
            },
            (err) => {
              console.error(err);
            }
          );
        } else {
          this.navigationService.navigateForward('/tax-keys/tax-form');
        }
        return;
      case UDEstateENUM.ESTADO_REGISTRADO:
        this.navigationService.navigateRoot('/check-account');
        return;
      case UDEstateENUM.ESTADO_EMAIL_VALIDADO:
        this.navigationService.navigateRoot('/plans/choice-type');
        return;
      case UDEstateENUM.ESTADO_PLAN_SELECCIONADO:
        const navigationExtras: NavigationExtras = { state: { usuarioTSID: id } };
        if (this.userData.domicilio_fiscal) {
          this.navigationService.navigateRoot('/plans/detail', { state: { canBuy: true } });
        } else {
          this.navigationService.navigateRoot('/plans/choice-type/step0', navigationExtras);
        }
        return;
      case UDEstateENUM.ESTADO_REVISION:
      case UDEstateENUM.ESTADO_SERVICIO_CONTABLE:
      case UDEstateENUM.ESTADO_REVISION_CLAVE_FISCAL:
        const modal = await this.modalController.create({
          component: PendingReviewComponent,
          backdropDismiss: false,
          cssClass: 'modal-bienvenido',
        });
        modal.present();
        return;
    }
  }

  openNotifications() {
    this.navigationService.navigateForward('/notifications');
  }

  private isMobile(): boolean {
    return /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent);
  }

  close() {
    this.modal = false;
  }
  goToApple() {
    window.location.href = 'https://apps.apple.com/ar/app/tributo-simple-monotributo/id1571573160';
  }
  goToAndroid() {
    window.location.href = 'https://play.google.com/store/apps/details?id=tributosimple.anfler.com.ar&hl=es_AR';
  }
  goToNone(event: Event) {
    event.stopPropagation(); // Evita que el evento 'click' se propague al contenedor modal
    this.modal = true;
  }
}
