import { Injectable } from '@angular/core';
import { PushNotification, PushNotificationActionPerformed, PushNotificationToken, PushNotifications } from '@capacitor/push-notifications';
import { Platform } from '@ionic/angular';
import { ApiUserService } from '../api-user.service';


@Injectable({
  providedIn: 'root'
})
export class PushNotificationService {

  private readonly TAG = 'PushNotificationService -';

  constructor(private platform: Platform, private apiUserS: ApiUserService) {
    console.log(this.TAG, 'load');
  }

  async initService() {
    if (this.platform.is('hybrid')) {
      PushNotifications.requestPermissions().then(result => {
        if (result.receive == 'granted') {
          console.log(this.TAG, 'requestPermission - Granted: ', result);
          PushNotifications.register();
        } else {
          console.log(this.TAG, 'requestPermission - Not granted: ', result);
        }
      }, err => {
        console.log(this.TAG, 'requestPermission - ERR: ', err);
      });
      this.setListeners();
    }
  }

  closeService() {
    if (this.platform.is('hybrid')) {
      this.updateRegId('');
      this.clearListeners();
    }
  }

  private setListeners() {
    console.log(this.TAG, 'setListeners');

    PushNotifications.addListener('registration', (token: PushNotificationToken) => {
      console.log(this.TAG, 'listener - registration: ', token);
      const tokenValue = token?.value;
      if (tokenValue) {
        this.updateRegId(tokenValue);
      } else {
        this.updateRegId('');
      }
    });

    PushNotifications.addListener('registrationError', (err: any) => {
      console.log(this.TAG, 'listener - registrationError: ', err);
    });

    PushNotifications.addListener('pushNotificationReceived', (notification: PushNotification) => {
      console.log(this.TAG, 'listener - pushNotificationReceived: ', notification);
    });

    PushNotifications.addListener('pushNotificationActionPerformed', (notification: PushNotificationActionPerformed) => {
      console.log(this.TAG, 'listener - pushNotificationActionPerformed: ', notification);
    });
  }

  private clearListeners() {
    PushNotifications.removeAllListeners();
    PushNotifications.removeAllDeliveredNotifications();
  }

  private updateRegId(regId: string) {
    if (regId?.length > 0) {
      this.apiUserS.postRegId(regId).subscribe(r => {
        console.log(r);
      }, err => {
        console.error(err);
      });
    }
  }

}
