export const environment = {
  production: true,
  tyc_url: 'https://backoffice.tributosimple.com/terminos-condiciones',
  version: '8.1.0',
  wa_number: '+5491166410871',
  afConfig: {
    appID: 'id1571573160', // replace with your app ID.
    devKey: 'NmbJUGx7NoD9WKZk4Xs7E8', // replace with your dev key.
    isDebug: false,
    waitForATTUserAuthorization: 10, // for iOS 14 and higher
    minTimeBetweenSessions: 6, // default 5 sec
    registerOnDeepLink: true,
    registerConversionListener: true,
    registerOnAppOpenAttribution: false,
    deepLinkTimeout: 4000, // default 3000 ms
    useReceiptValidationSandbox: true, // iOS only
    useUninstallSandbox: true, // iOS only
  },
  baseUrl: {
    ar: 'https://backoffice.tributosimple.com',
    pe: 'https://backoffice.tributosimple.com',
    uy: 'https://backoffice.tributosimple.com',
  },
  apiUrl: {
    ar: 'https://backoffice.tributosimple.com/api/v1',
    pe: 'https://backoffice.tributosimple.com/api/v1/pe',
    uy: 'https://backoffice.tributosimple.com/api/v1/uy',
  },
  urlWeb: {
    ar: 'https://tributosimple.com/argentina/#planes',
    pe: 'https://tributosimple.com/peru/#planes',
    uy: 'https://tributosimple.com/uruguay/#planes',
  },
  // zoho: {
  //   android: {
  //     appKey: 'DaWEJn3I1IUG4uhDLsNNyzaapXLuPJE2ehy7xzW1Q86NHIJFHaT5epIF8WlCkR58',
  //     accessKey:
  //       'DaWEJn3I1IWV7XauXfhYKhW14HO3%2BgiIavbzc4w%2BTgpM4BmQOXEiPLFzVMptKPLaKRu3A3K0Qv78NMIhw13G694aYF8viAp07a3JvVkbEwPJlFak2i0chMXq1roLOOrAfrZbnHUDkY87p%2F7Ylc3SVBgs%2BbRP01U4m4RBDbAI94Y%3D',
  //   },
  //   ios: {
  //     appKey: 'DaWEJn3I1IUG4uhDLsNNyzaapXLuPJE2ehy7xzW1Q86NHIJFHaT5epIF8WlCkR58',
  //     accessKey:
  //       'DaWEJn3I1IWV7XauXfhYKhW14HO3%2BgiIavbzc4w%2BTgpM4BmQOXEiPLFzVMptKPLaKRu3A3K0Qv5IzLgjloHfR%2BRlPUqVCb6x2NjGEUusRVK17asageZCHs6BqXRW5BXgfrZbnHUDkY87p%2F7Ylc3SVBgs%2BbRP01U4m4RBDbAI94Y%3D',
  //   },
  // },
};

export const TOKENMail = 'cad087f03c45d9de2afecd9be8a62015';
export const TOKEN = 'eyJhbGciOiJIUzUxMiJ9.eyJidXNpbmVzc0lkIjoidHJpYnV0b3NpbXBsZSIsIm5hbWUiOiJBcmllbCBDb2xxdWUiLCJhcGkiOnRydWUsImlkIjoiVW93MWwyQjVwVFU2a1N1WE9PY1EzNUlHcDZtMSIsImV4cCI6MTg3MTY0MjMwNCwianRpIjoiVW93MWwyQjVwVFU2a1N1WE9PY1EzNUlHcDZtMSJ9.p9237Okg5ehgk9RuaRKLAR0lsjVzimluuZm9ggtvsMgFZVGVHwJzb-nPVobz7SykTLDB4LcoFwrO4WUoMF1yZQ';
export const API_URL = 'https://backoffice.tributosimple.com/api/v1';
export const BASE_URL = 'https://backoffice.tributosimple.com';
export const PAYMENT_MP_URL = 'https://backoffice.tributosimple.com/mercado-pago/contratar-servicio-contable?usuarioTSID=${usuarioTSID}';
export const SUS_MP_URL = 'https://backoffice.tributosimple.com/mercado-pago/debito-automatico/suscripcion?external_reference=${usuarioTSID}';
export const PAYMENT_TS_MP_URL = 'https://backoffice.tributosimple.com/mercado-pago/pagar-deuda?usuarioTSID=${usuarioTSID}';
export const PAYMENTS_URL = 'https://pagos.tributosimple.com';
export const SHOWERRORS = false;
