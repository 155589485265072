import { Component, Input } from '@angular/core';
import { UserDataService } from 'src/app/services/user/user-data.service';

@Component({
  selector: 'app-user-profile-avatar',
  templateUrl: './user-profile-avatar.component.html',
  styleUrls: ['./user-profile-avatar.component.scss'],
})
export class UserProfileAvatarComponent {
  @Input() width = '80px';
  @Input() height = '80px';

  profilePhoto: string;

  constructor(private userDataS: UserDataService) {
    this.userDataS.getUserProfilePhoto().subscribe((profilePhoto) => {
      this.profilePhoto = profilePhoto;
    });
  }
}
